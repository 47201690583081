<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import Multiselect from "vue-multiselect";
    //import DatePicker from "vue2-datepicker";
    import CKEditor from "@ckeditor/ckeditor5-vue";
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
    import { apiRequest } from "@/helpers/api-call";
    import { format } from "date-fns";
    import router from "@/router/index.js";
    import Vue from "vue";
    
    /**
     * Orders Component
     */
    export default {
      components: {
        Layout,
        PageHeader,
        Multiselect,
        ckeditor: CKEditor.component,
      },
      data() {
        return {
          title: "liste des agences",
          items: [
            {
              text: "Dashboard",
            },
            {
              text: "liste",
              active: true,
            },
          ],
          tyMedia: [
            {
              name: "",
              value: 0,
            },
            {
              name: "Image",
              value: "image",
            },
            {
              name: "Vidéo",
              value: "video",
            },
          ],
          tyMessage: [
            {
              name: "Notification",
              value: 0,
            },
            {
              name: "Mail",
              value: 1,
            },
            {
              name: "Notification + Mail",
              value: 2,
            },
          ],
          userType: [
            {
              name: "CONDUCTEUR",
            },
            {
              name: "PARTICULER",
            },
            {
              name: "TAXIMAN",
            },
            {
              name: "SOCIETE",
            },
          ],
          url_link: "",
          userTypeSelect: "",
          messageSelect: null,
          mediaSelect: null,
          messageContent: [
            {
              sujet: "",
              message: "",
              userId: "",
            },
          ],
          value: null,
          value1: null,
          options: ["Sexe", "Taille", "Vehicule", "je vois"],
          idUser: 0,
          dataUser: [],
          InactifData:[],
          ActifData:[],
          dataUserD: [],
          nbreUser: "",
          totalRows: 1,
          currentPage: 1,
          perPage: 100,
          pageOptions: [100, 250, 500],
          filter: null,
          filterOn: [],
          sortBy: "orderid",
          sortDesc: false,
    
          fields: [
            { key: "designation", sortable: true, label: "Nom agence" },
            /* { key: "date", sortable: true }, */
            { key: "tel", sortable: true, label: "Contact" },
            { key: "ifu", label: "IFU" },
            { key: "nbreVehicule", sortable: true, label: "Nombres de véhicules" },
            { key: "email", sortable: true, label: "Email" },
            {key: "responsable",sortable: true,label: "Nom & Prénom du dirigeant",},
            { key: "tel", sortable: true, label: "Contact du dirigeant" },
            { key: "action", label: "Actions" },
          ],
          field: [
            { key: "designation", sortable: true, label: "Nom agence" },
            /* { key: "date", sortable: true }, */
            { key: "tel", sortable: true, label: "Contact" },
            { key: "ifu", label: "IFU" },
            { key: "nbreVehicule", sortable: true, label: "Nombres de véhicules" },
            { key: "email", sortable: true, label: "Email" },
            {key: "responsable",sortable: true,label: "Nom & Prénom du dirigeant",},
            { key: "tel", sortable: true, label: "Contact du dirigeant" },
            { key: "action", label: "Actions" },
          ],
          showDisable: false,
          showDelete: false,
          showEdit: false,
          showSucess: false,
          showEchec: false,
          showSucessEdit: false,
          showEchecEdit: false,
          userToUse: 0,
          infoUser: {
            id: "",
            firstname: "",
            lastname: "",
            profession: "",
            birthday: "",
            adresse: "",
            ville: "",
            pays: "",
            userType: "",
            tel: "",
            email: "",
            personneUrgence: "",
            contactUrgence: "",
            name: "",
            boitePostal: "",
            apropos: "",
            permisCategorie: "",
            permisAnnee: "",
            permisNumuero: "",
          },
          showMessage: false,
          showMessageMail: false,
          editor: ClassicEditor,
          editorData: "",
            totalActif:0,
            totalInactif:0
        };
      },
      computed: {
        /**
         * Total no. of records
         */
        rows() {
          return this.dataUser.length;
        },
      },
      async mounted() {
        this.init();
      },
      methods: {
        async init() {
          //Chargement des données
          const data = await apiRequest("GET", "admin/rmo/agence", undefined, false);
          if (data && data.data) {
           console.log("liste des agences:", data.data);
            const formattedTable = data.data.agenceActive.map((agence) => {
              var id= agence.id;
              var designation = agence.designation;
              var email = agence.email;
              var ifu= agence.ifu;
              var responsable=agence.responsable;
              var tel=agence.tel;
              var ville=agence.ville;
              var nbreVehicule=agence.nbreVehicule;
              return {
                id: id,
                designation: designation,
                email: email,
                ifu: ifu,
                nbreVehicule:nbreVehicule,
                responsable: responsable,
                tel: tel,
                ville: ville,
              };
            });
            this.ActifData = formattedTable;
            this.totalActif=this.ActifData.length
          
          } if(data && data.data){
    
           const formattedTable = data.data.agenceInActive.map((agence) => {
              var id= agence.id;
              var designation = agence.designation;
              var email = agence.email;
              var ifu= agence.ifu;
              var responsable=agence.responsable;
              var tel=agence.tel;
              var ville=agence.ville;
              var nbreVehicule=agence.nbreVehicule;
              return {
                id: id,
                designation: designation,
                email: email,
                ifu: ifu,
                nbreVehicule:nbreVehicule,
                responsable: responsable,
                tel: tel,
                ville: ville,
              };
            });
            this.InactifData = formattedTable;
            this. totalInactif= this.InactifData.length
    
          }else{
            console.log("pas de data");
          }
        },
        async addMessage() {
          this.showMessage = false;
          this.showMessageMail = false;
          const data = await apiRequest(
            "POST",
            "admin/message-user",
            {
              idUser: this.messageContent.userId,
              typeMessage: this.messageSelect.value,
              subject: this.messageContent.sujet,
              text: this.messageContent.message,
              media_type: this.tyMedia.value ? this.tyMedia.value : "",
              url: this.url_link,
            },
            false
          );
          //console.log("data.data:", data);
          if (data && data.data) {
            this.showSucess = true;
            this.init();
            console.log(data);
          } else {
            this.showEchec = true;
            this.init();
            console.log(data);
          }
        },
        async ModifSubmit() {
          this.showEdit = false;
          /* if (this.infoUser.userType.name && this.infoUser.userType.name.data) {
            this.userTypeSelect = this.infoUser.userType.name;
          } */
          const Editdata = await apiRequest(
            "PUT",
            "users/" + this.infoUser.id,
            {
              idUser: this.infoUser.id,
              lastname: this.infoUser.lastname,
              firstname: this.infoUser.firstname,
              tel: this.infoUser.telephone,
              email: this.infoUser.email,
              adresse: this.infoUser.adresse,
              ville: this.infoUser.ville,
              pays: this.infoUser.pays,
              name: this.infoUser.name,
              boitePostal: this.infoUser.boitePostal,
              birthday: this.infoUser.birthday,
              userType: this.userTypeSelect,
              profession: this.infoUser.profession,
              apropos: this.infoUser.apropos,
              permisCategorie: this.infoUser.permisCategorie,
              permisAnnee: this.infoUser.permisNumuero,
              permisNumuero: this.infoUser.permisNumuero,
              personneUrgence: this.infoUser.contactUrgence,
              contactUrgence: this.infoUser.contactUrgence,
            },
            false
          );
    
          if (Editdata && Editdata.data) {
            this.showSucessEdit = true;
            this.init();
            //console.log(Editdata);
          } else {
            this.showEchecEdit = true;
            this.init();
            //console.log(Editdata);
          }
    
          console.log(this.infoUser.id);
        },
        EditModal() {
          this.submitted = false;
          this.showEdit = false;
        },
        async editClick(row) {
          this.showEdit = true;
          this.infoUser.id = row.id;
          /* this.infoUser.lastname = row.lastname;
          this.infoUser.firstname = row.firstname;
          this.infoUser.profession = row.profession;
          this.infoUser.adresse = row.adresse;
          this.infoUser.ville = row.ville;
          this.infoUser.pays = row.pays;
          this.infoUser.email = row.email;
          this.infoUser.contactUrgence = row.contactUrgence;
          this.infoUser.personneUrgence = row.personneUrgence;
          this.infoUser.telephone = row.tel;
          this.infoUser.userTypeSelect = row.userType */
    
          const dataDetail = await apiRequest(
            "POST",
            "admin/detail-user",
            { id: row.id },
            false
          );
    
          if (dataDetail && dataDetail.data) {
            //console.log("Detail User", dataDetail.data)
            this.infoUser.lastname = dataDetail.data.personne.lastname;
            this.infoUser.firstname = dataDetail.data.personne.firstname;
            this.infoUser.profession = dataDetail.data.personne.profession;
            (this.infoUser.birthday = format(
              new Date(dataDetail.data.personne.birthday),
              "dd/MM/yyyy hh:mm:ss"
            )),
              (this.infoUser.adresse = dataDetail.data.personne.adresse);
            this.infoUser.ville = dataDetail.data.personne.ville;
            this.infoUser.pays = dataDetail.data.personne.pays;
            this.infoUser.email = dataDetail.data.personne.email;
            this.infoUser.contactUrgence = dataDetail.data.personne.contactUrgence;
            this.infoUser.personneUrgence =
              dataDetail.data.personne.personneUrgence;
            this.infoUser.telephone = dataDetail.data.personne.tel;
            this.infoUser.userTypeSelect = dataDetail.data.personne.userType;
          }
    
          console.log("type ", this.infoUser.userTypeSelect);
          console.log("row ", row);
        },
        desactiverUser(row) {
          this.userToUse = row.id;
          //console.log('userToDel:', this.userToUse)
          this.showDelete = true;
        },
        async desactiveAgence() {
          this.showDelete = false;
          const data = await apiRequest(
            "POST",
            "admin/rmo/agence/action",
            { id: this.userToUse,actif:false },
            false
          );
          //console.log("data.data:", data);
          if (data && data.data) {
            this.position();
            this.init();
          }
        },
        messageShow(row, typeMessage) {
          this.messageSelect = typeMessage;
          //console.log('typeMessage:', typeMessage)
          if (typeMessage.value == 0) this.showMessage = true;
          if (typeMessage.value == 1) this.showMessageMail = true;
          this.messageContent.userId = row.id;
          //console.log(row.id);
        },
        async activeAgence(row) {
          this.userToUse = row.id;
          const data = await apiRequest(
            "POST",
            "admin/rmo/agence/action",
            { id: this.userToUse ,actif:true},
            false
          );
          //console.log("data.data:", data);
          if (data && data.data) {
            this.position();
            this.init();
          }
        },
        position() {
          Vue.swal({
            position: "center-end",
            icon: "success",
            title: "Mise à jour effectuée",
            showConfirmButton: false,
            timer: 3000,
          });
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
        },

        
        goToDetail(row) {
          console.log("row:", row.id);
          router.push(`/agence/detail_agence/${row.id}`);
          //router.push('/user/user_info/'+row.id)
        },




        
      },
    };
    </script>
    
    <template>
      <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body pt-0">
                <b-tabs nav-class="nav-tabs-custom">
                  <b-tab title-link-class="p-3">
                    <template v-slot:title>
                      <a class="font-weight-bold active">Liste des agences actives</a>
                    </template>
                    <div class="row  d-flex justify-content-between align-items-center mt-4">
                      <div class="p-3">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Afficher&nbsp;
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                            ></b-form-select
                            >&nbsp;entrées
                          </label>
                        </div>
                      </div>
                    
                      <!-- Search -->
                      <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>
                      <!-- End search -->
                    </div>
                    <div class="table-responsive">
                      <b-table
                        style="cursor: pointer"
                        class="table-centered"
                        :items="ActifData"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        @row-clicked="goToDetail"
                      >
                        <template v-slot:cell(badge)="row">
                          <div
                            class="py-2 d-flex justify-content-center"
                            :class="{
                              'fa fa-star': `${row.value}` === 'false',
                              'fa fa-star badge-soft-warning':
                                `${row.value}` === 'true',
                            }"
                          ></div>
                        </template>
                        <template v-slot:cell(statut)="row">
                          <div
                            class="py-2 d-flex justify-content-center badge font-size-12"
                            :class="{
                              'badge-soft-danger': `${row.value}` === 'Suspendu',
                              'badge-soft-success': `${row.value}` === 'Actif',
                            }"
                          >
                            {{ row.value }}
                          </div>
                        </template>
                        <template v-slot:cell(action)="row">
                          <a
                            href="javascript:void(0);"
                            class="text-primary"
                            v-b-tooltip.hover
                            @click="editClick(row.item)"
                            title="Modifier"
                          >
                            <i class="mdi mdi-pencil font-size-18"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="text-danger"
                            v-b-tooltip.hover
                            @click="desactiverUser(row.item)"
                            title="Suspendre"
                          >
                            <i class="mdi mdi-close font-size-18"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="text-success"
                            @click="
                              messageShow(row.item, { name: 'Mail', value: 1 })
                            "
                            v-b-tooltip.hover
                            title="Mail"
                          >
                            <i class="fas fa-envelope"></i>
                          </a>
                          
                        </template>
                        <template v-slot:cell(tel)="row" style="text-align: center">
                          <a
                            :href="'https://wa.me/' + row.value"
                            class="text-success"
                            v-b-tooltip.hover
                            title="Envoyer un message whatsapp"
                            target="_blank"
                          >
                            {{ row.value }}
                          </a>
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="totalActif"
                              :per-page="perPage"
                              :rows="ActifData"

                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </b-tab>
    
                  <b-tab title-link-class="p-3">
                    <template v-slot:title>
                      <a class="font-weight-bold active"
                        >Liste des agences suspendues</a
                      >
                    </template>
                    <div class="row  d-flex justify-content-between align-items-center mt-4">
                      <div class="p-3">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Afficher&nbsp;
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                            ></b-form-select
                            >&nbsp;entrées
                          </label>
                        </div>
                      </div>
                      
                      <!-- Search -->
                      <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>
                      <!-- End search -->
                    </div>
                    <div class="table-responsive">
                      <b-table
                        style="cursor: pointer"
                        class="table-centered"
                        :items="InactifData"
                        :fields="field"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        @row-clicked="goToDetail"
                      >
                        <template v-slot:cell(badge)="row">
                          <div
                            class="py-2 d-flex justify-content-center"
                            :class="{
                              'fa fa-star': `${row.value}` === 'false',
                              'fa fa-star badge-soft-warning':
                                `${row.value}` === 'true',
                            }"
                          ></div>
                        </template>
                        <template v-slot:cell(statut)="row">
                          <div
                            class="py-2 d-flex justify-content-center badge font-size-12"
                            :class="{
                              'badge-soft-danger': `${row.value}` === 'Suspendu',
                              'badge-soft-success': `${row.value}` === 'Actif',
                            }"
                          >
                            {{ row.value }}
                          </div>
                        </template>
                        <template v-slot:cell(action)="row">
                          <a
                            href="javascript:void(0);"
                            class="text-success"
                            v-b-tooltip.hover
                            @click="activeAgence(row.item)"
                            title="Autoriser"
                          >
                            <i class="mdi mdi-check font-size-18"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="text-danger"
                            @click="
                              messageShow(row.item, { name: 'Mail', value: 1 })
                            "
                            v-b-tooltip.hover
                            title="Mail"
                          >
                            <i class="fas fa-envelope"></i>
                          </a>
                        </template>
                        <template v-slot:cell(tel)="row" style="text-align: center">
                          <a
                            :href="'https://wa.me/' + row.value"
                            class="text-success"
                            v-b-tooltip.hover
                            title="Envoyer un message whatsapp"
                            target="_blank"
                          >
                            {{ row.value }}
                          </a>
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="totalInactif"
                              :per-page="perPage"
                              :rows="InactifData"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <b-modal
          id="modal-backdrop"
          centered
          v-model="showDelete"
          title="Voulez-vous vraiment supprimer cette agence ?"
          title-class="font-18"
          hide-footer
        >
          <div class="row float-right mt-3">
            <b-button variant="danger" @click="showDelete = false">Non</b-button>
            <b-button variant="success" class="ml-3 mr-4" @click="desactiveAgence"
              >Oui</b-button
            >
          </div>
        </b-modal>
    
        <b-modal
          id="modal-backdrop"
          centered
          v-model="showEchec"
          title="L'envoie a échoué"
          title-class="font-18"
          hide-footer
        >
          <div class="row float-right mt-3">
            <b-button variant="danger" @click="showEchec = false"
              >Reéssayez</b-button
            >
          </div>
        </b-modal>
    
        <b-modal
          id="modal-backdrop"
          centered
          v-model="showSucessEdit"
          title="Agence modifiée avec succès !!!"
          title-class="font-18"
          hide-footer
        >
          <div class="row float-right mt-3">
            <b-button variant="success" @click="showSucessEdit = false"
              >OK</b-button
            >
          </div>
        </b-modal>
    
        <b-modal
          id="modal-backdrop"
          centered
          v-model="showEchecEdit"
          title="La modification a échouée"
          title-class="font-18"
          hide-footer
        >
          <div class="row float-right mt-3">
            <b-button variant="danger" @click="showEchecEdit = false"
              >Reéssayez</b-button
            >
          </div>
        </b-modal>
    
        <b-modal
          id="modal-center"
          centered
          size="xl"
          v-model="showEdit"
          title="Editer véhicule"
          title-class="font-18"
          hide-footer
        >
          <form enctype="multipart/form-data" @submit.prevent="submitCar">
            <div class="row">
              <div class="col-md-6">
                <label for="">Nom de l'agence</label>
                <input type="text" class="form-control" id="" />
              </div>
              <div class="col-md-6">
                <label for="">Nom du dirigeant</label>
                <input type="text" class="form-control" id="" />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <label for="">RCCM</label>
                <input type="text" class="form-control" id="" />
              </div>
              <div class="col-md-6">
                <label for="">Prénom</label>
                <input type="text" class="form-control"  id="" />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <label for="">IFU</label>
                <input
                  type="text"
                  class="form-control"
                  
                  id=""
                />
                <!-- <multiselect
                        v-model="motorisationSelect"
                        :options="motorisation"
                        label="name"
                        track-by="name"
                      ></multiselect> -->
              </div>
              <div class="col-md-6">
                <label for="">Contact</label>
                <input
                  type="text"
                  class="form-control"
                  
                  id=""
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <label for="">Contact</label>
                <input type="text" class="form-control"  id="" />
              </div>
              <div class="col-md-6">
                <label for="">Email</label>
                <input type="text" class="form-control" id="" />
              </div>
              <div class="col-md-6 mt-4">
                <label for="">Nombre de véhicule</label>
                <input type="text" class="form-control" id="" />
              </div>
              <div class="col-md-6 mt-4">
                <label for="">Mot de passe</label>
                <input type="text" class="form-control" id="" />
                <!-- <multiselect
                        v-model="climatisationSelect"
                        :options="climatisation"
                        label="name"
                        track-by="name"
                      ></multiselect> -->
              </div>
            </div>
            <div class="form-group mt-4">
              <button class="btn btn-primary float-right">Modifier</button>
            </div>
          </form>
        </b-modal>
    
        <!-- ENVOYER LE MESSAGE NOTIF-->
        <b-modal v-model="showMessage" title="Nouveau Message" centered>
          <form>
            <div class="form-group">
              <label class="control-label">Choisir le type de message:</label>
              <multiselect
                v-model="messageSelect"
                :options="tyMessage"
                label="name"
                track-by="value"
              ></multiselect>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Sujet"
                v-model="messageContent.sujet"
              />
            </div>
            <div class="form-group mb-2">
              <!--  <ckeditor
                v-model="messageContent.message"
                :editor="editor"
                aria-placeholder="Votre Message"
              ></ckeditor> -->
              <textarea
                v-model="messageContent.message"
                placeholder="Votre message"
                class="form-control"
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div class="form-group">
              <multiselect
                v-model="mediaSelect"
                :options="tyMedia"
                placeholder="Image, vidéo"
                label="name"
                track-by="value"
              ></multiselect>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="url_link"
                placeholder="Lien du média"
              />
            </div>
          </form>
          <template v-slot:modal-footer>
            <b-button variant="secondary" @click="showMessage = false"
              >Fermer</b-button
            >
            <b-button variant="primary" @click="addMessage">
              Envoyer
              <i class="fab fa-telegram-plane ml-1"></i>
            </b-button>
          </template>
        </b-modal>
        <!-- -->
    
        <!-- ENVOYER LE MESSAGE MAIL-->
        <b-modal v-model="showMessageMail" title="Nouveau Message" centered>
          <form>
            <div class="form-group">
              <label class="control-label">Choisir le type de message:</label>
              <multiselect
                v-model="messageSelect"
                :options="tyMessage"
                label="name"
                track-by="value"
              ></multiselect>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Sujet"
                v-model="messageContent.sujet"
              />
            </div>
            <div class="form-group mb-2">
              <ckeditor
                v-model="messageContent.message"
                :editor="editor"
                aria-placeholder="Votre Message"
              ></ckeditor>
            </div>
            <div class="form-group">
              <multiselect
                v-model="mediaSelect"
                :options="tyMedia"
                placeholder="Image, vidéo"
                label="name"
                track-by="value"
              ></multiselect>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="url_link"
                placeholder="Lien du média"
              />
            </div>
          </form>
          <template v-slot:modal-footer>
            <b-button variant="secondary" @click="showMessage = false"
              >Fermer</b-button
            >
            <b-button variant="primary" @click="addMessage">
              Envoyer
              <i class="fab fa-telegram-plane ml-1"></i>
            </b-button>
          </template>
        </b-modal>
        <!-- -->
      </Layout>
    </template>
    <style>
       thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link {
   background: #dc143c;
   transition: all .5s;
 }
  .nav-tabs .nav-link a{
  color: #fff ;
} 

.text-red{
  color:#dc143c;
}  .nav-tabs .nav-link a{
  color: #fff!important ;
}

.text-green{
  color:#3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: blue;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35;
  border-bottom: 1px solid blue;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000;
}
    </style>